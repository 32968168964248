<template>
  <div class="bg-gray-100">
    <!-- Background color split screen for large screens -->
    <!-- <div class="hidden lg:block fixed top-0 left-0 w-1/2 h-full bg-white" aria-hidden="true" />
        <div
            class="hidden lg:block fixed top-0 right-0 w-1/2 h-full bg-gray-50"
            aria-hidden="true"
            />-->
    <Html class="h-full bg-white dark">
      <Head>
        <Title>{{ tenantConfig && tenantConfig.pageTitlePrefix }} Buy Tickets</Title>
      </Head>
    </Html>

    <Body class="h-full" />

    <div v-if="!tenantConfig.theme">
      <FallbackTenantLayout />
    </div>
    <div v-if="tenantConfig.theme">
      <header class="relative bg-white border-b border-gray-200 text-sm font-medium text-gray-700">
        <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <div class="relative flex justify-around">
            <a href="#" class="top-1/2 mt-2">
              <span class="sr-only">{{ tenantConfig.theme.brandName }}</span>
              <img
                :src="tenantConfig.theme.headerLogoImageSrc"
                :alt="tenantConfig.theme.headerLogoImageAlt || tenantConfig.theme.brandName || 'Logo'"
                class="h-10 w-auto"
              >
            </a>
            <!-- <LayoutHeaderCartProgressNav /> -->
            <!-- <LayoutHeaderCartProgressBack v-if="hasRouteHistory" /> -->
            <!-- <nav aria-label="Progress" class="hidden sm:block">
                            <ol role="list" class="flex space-x-4">
                                <li
                                    v-for="(step, stepIdx) in steps"
                                    :key="step.name"
                                    class="flex items-center"
                                >
                                    <a
                                        v-if="step.status === 'current'"
                                        :href="step.href"
                                        aria-current="page"
                                        class="text-primary-600"
                                    >{{ step.name }}</a>
                                    <a v-else :href="step.href">{{ step.name }}</a>
                                    <ChevronRightIcon
                                        v-if="(stepIdx !== steps.length - 1)"
                                        class="w-5 h-5 text-gray-300 ml-4"
                                        aria-hidden="true"
                                    />
                                </li>
                            </ol>
                            </nav>-->
            <!-- <p class="sm:hidden">Buy Tickets</p> -->
          </div>
        </div>
      </header>
      <nav
        v-if="tenantConfig && tenantConfig.theme && tenantConfig.theme.showCartSteps"
        class="bg-white border-b border-gray-200 flex"
        aria-label="Breadcrumb"
      >
        <ol role="list" class="max-w-screen-xl w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
          <li v-for="page in pages" :key="page.name" class="flex">
            <div class="flex items-center">
              <svg
                class="flex-shrink-0 w-6 h-full text-gray-200"
                viewBox="0 0 24 44"
                preserveAspectRatio="none"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
              </svg>
              <a
                :href="page.href"
                class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
                :class="page.current ? 'text-primary-600' : ''"
                :aria-current="page.current ? 'page' : undefined"
              >{{ page.name }}</a>
            </div>
          </li>
        </ol>
      </nav>
      <slot />
      <footer aria-labelledby="footer-heading" class="bg-white">
        <h2 id="footer-heading" class="sr-only">
          Footer
        </h2>
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="border-t border-gray-200 py-20">
            <div
              class="grid grid-cols-1 md:grid-cols-12 md:grid-flow-col md:gap-x-8 md:gap-y-16 md:auto-rows-min"
            >
              <div
                class="mt-10 col-span-6 grid grid-cols-2 gap-8 sm:grid-cols-3 md:mt-0 md:row-start-1 md:col-start-3 md:col-span-8 lg:col-start-2 lg:col-span-8"
              >
                <div
                  class="grid grid-cols-1 gap-y-4 sm:col-span-4 sm:grid-cols-3 sm:gap-x-8 justify-center"
                >
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">
                      Legal
                    </h3>
                    <ul role="list" class="mt-6 space-y-6">
                      <li class="text-sm">
                        <a
                          href="https://tunesinthedunes.co.uk/ticket-terms"
                          target="_blank"
                          class="text-gray-500 hover:text-gray-600"
                        >Terms & Conditions</a>
                      </li>
                      <li class="text-sm">
                        <a
                          href="https://tunesinthedunes.co.uk/tickets-privacy-policy/"
                          target="_blank"
                          class="text-gray-500 hover:text-gray-600"
                        >Privacy
                          Policy</a>
                      </li>
                      <!-- <li class="text-sm">
                                                <a
                                                    class="text-gray-500 hover:text-gray-600"
                                                >Refund Policy</a>
                                                </li>-->
                    </ul>
                  </div>
                  <!-- <div>
                                        <h3 class="text-sm font-medium text-gray-900">Legal</h3>
                                        <ul role="list" class="mt-6 space-y-6">
                                            <li class="text-sm">
                                                <a
                                                    href="https://tunesinthedunes.co.uk/ticket-terms"
                                                    target="_blank"
                                                    class="text-gray-500 hover:text-gray-600"
                                                >Terms & Conditions</a>
                                            </li>
                                            <li class="text-sm">
                                                <a
                                                    href="https://tunesinthedunes.co.uk/tickets-privacy-policy/"
                                                    target="_blank"
                                                    class="text-gray-500 hover:text-gray-600"
                                                >Privacy Policy</a>
                                            </li>
                                        </ul>
                                        </div>-->
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">
                      Support
                    </h3>
                    <ul role="list" class="mt-6 space-y-6">
                      <li class="text-sm">
                        <NuxtLink
                          :to="`/buy/tickets-${route.params.tenant}/recover-tickets`"
                          class="text-gray-500 hover:text-gray-600"
                        >
                          Lost Tickets?
                        </NuxtLink>
                      </li>
                      <!-- <li class="text-sm">
                                                <a href="https://tunesinthedunes.co.uk/contact" target="_blank"
                                                    class="text-gray-500 hover:text-gray-600">Contact Us</a>
                                                </li> -->
                    </ul>
                  </div>
                </div>
                <!-- <div>
                                    <h3 class="text-sm font-medium text-gray-900">Support</h3>
                                    <ul role="list" class="mt-6 space-y-6">
                                        <li
                                            v-for="item in footerNavigation.customerService"
                                            :key="item.name"
                                            class="text-sm"
                                        >
                                            <a
                                                :href="item.href"
                                                class="text-gray-500 hover:text-gray-600"
                                            >{{ item.name }}</a>
                                        </li>
                                    </ul>
                                    </div>-->
              </div>
            </div>
          </div>

          <LayoutFooterPoweredByWetix />
        </div>
      </footer>
      <NotificationBasic />
    </div>
  </div>
</template>

<script setup lang="ts">

// Import stores
import { useTenantConfig } from '~/stores/tenantconfig'

// Use composables
const route = useRoute()

//  Import Pinia stores
const tenantConfig = useTenantConfig()

// const { tenant } = route.params

// Fetch tenant theme from back-end (proxies to firestore) and set in store
try {
  const { data: tenantTheme } = await useFetch(`/api/tenant-config?tenantId=${route.params.tenant}&config=theme`) as any
  console.log('Got tenant theme: ', { theme: tenantTheme.value })
  tenantConfig.$patch({ tenantId: `${route.params.tenant}`, theme: { ...tenantTheme.value } })
} catch (error) {
  console.error(`Failed to fetch tenant theme for tenant ${route.params.tenant}`, error)
  console.error(error)
  tenantConfig.$patch({ tenantId: `${route.params.tenant}` })
  tenantConfig.setFallbackTheme() // Sets a fallback theme.
}

const pages = [
  { name: 'Select Tickets', href: '#', current: true },
  { name: 'Checkout', href: '#', current: false },
  { name: 'Confirmation', href: '#', current: false }
]

</script>
